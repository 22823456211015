import API from "../API/API";

import { APIErrorCallback, APISuccessCallback } from "../API/Types/API";

class OrderAPI {
  private static endpoint = `/order`;

  static async orderList(
    data: any,
    successCallback: APISuccessCallback,
    errorCallback: APIErrorCallback
  ): Promise<void> {
    API.call({
      endpoint: `${OrderAPI.endpoint}/list`,
      options: {
        method: "post",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      },
      successCallback: (res) => {
        return successCallback(res);
      },
      errorCallback: (error) => {
        return errorCallback(error);
      },
    });
  }

  static async sendMessageToDriver(
    id: number,
    message: string,
    successCallback: APISuccessCallback,
    errorCallback: APIErrorCallback
  ): Promise<void> {
    API.call({
      endpoint: `${OrderAPI.endpoint}/send-message`,
      options: {
        method: "post",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id,
          message,
        }),
      },
      successCallback: (res) => {
        return successCallback(res);
      },
      errorCallback: (error) => {
        return errorCallback(error);
      },
    });
  }
}

export default OrderAPI;
