import { createContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { MainContextState } from "./Types/Main";

const defaultValues = {
  main: {
    title: "Web Template",
    apiUrl: process.env.REACT_APP_API_URL,
    appUrl: process.env.REACT_APP_APP_URL,
  },
};

export const MainContext = createContext(defaultValues);

const MainContextProvider = (props: any) => {
  const location = useLocation();

  const [main, setMainContext] = useState<MainContextState>(defaultValues.main);

  useEffect(() => {}, [location]);

  return (
    <MainContext.Provider value={{ main }}>
      {props.children}
    </MainContext.Provider>
  );
};

export default MainContextProvider;
