import { PageProps } from "../layouts/Page/Types/Page";

const NotFoundPage = ({ t }: PageProps) => {

    return (
        <>
            <div className='not-found-container text-center'>
                <div className="fonts font-32">{t('errors.PAGE_NOT_FOUND').toString()}</div>
            </div>
        </>
    );
};

export default NotFoundPage;
