import { useCallback, useContext } from "react";

import { ComponentContext } from "../../contexts/Component/Context";

import {
  AlertActionType,
  AlertState,
} from "../../contexts/Component/Types/Alert";

import "./Styles/Alerts.scss";
import Alert from "./Alert";

const Alerts = (props: any) => {
  const { alerts, dispatchAlert } = useContext(ComponentContext);

  const hideAlert = useCallback(
    (index: number) => {
      const alertList: AlertState[] = JSON.parse(JSON.stringify(alerts));

      alertList[index].show = false;

      dispatchAlert({
        type: AlertActionType.HIDE,
        payload: {
          index: index,
          alert: { show: false },
        },
      });
    },
    [alerts]
  );

  return (
    <>
      {alerts.length > 0 && (
        <div id="alerts-wrapper" className={`d-flex flex-column-reverse`}>
          {alerts.map((alert, index) => (
            <Alert {...alert} index={index} hideAlert={hideAlert} />
          ))}
        </div>
      )}
    </>
  );
};

export default Alerts;
