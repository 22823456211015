import { LoaderAction, LoaderActionType, LoaderState } from "../Types/Loader";

const loaderReducer = (
  state: LoaderState,
  action: LoaderAction
): LoaderState => {
  switch (action.type) {
    case LoaderActionType.SHOW: {
      return {
        show: true,
      };
    }
    case LoaderActionType.HIDE: {
      return {
        show: false,
      };
    }
    default:
      return state;
  }
};

export { loaderReducer };
