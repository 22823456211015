import React, { createContext, useReducer } from "react";

import { AlertState } from "./Types/Alert";
import { alertReducer } from "./Reducers/Alert.reducer";

import { LoaderState } from "./Types/Loader";
import { loaderReducer } from "./Reducers/Loader.reducer";

const defaultValues = {
  loader: {
    show: false,
  },
  dispatchLoader: () => {},
  alerts: [],
  dispatchAlert: () => {},
};
export const ComponentContext = createContext<{
  loader: LoaderState;
  dispatchLoader: React.Dispatch<any>;
  alerts: AlertState[];
  dispatchAlert: React.Dispatch<any>;
}>(defaultValues);

const ComponentContextProvider = (props: any) => {
  const [loader, dispatchLoader] = useReducer(
    loaderReducer,
    defaultValues.loader
  );
  const [alerts, dispatchAlert] = useReducer(
    alertReducer,
    defaultValues.alerts
  );

  return (
    <ComponentContext.Provider
      value={{
        loader,
        dispatchLoader,
        alerts,
        dispatchAlert,
      }}
    >
      {props.children}
    </ComponentContext.Provider>
  );
};

export default ComponentContextProvider;
