import { useContext } from 'react';

import { ComponentContext } from "../../contexts/Component/Context";

import './Styles/Loader.scss';

import { PageLoaderProps } from "./Types/PageLoader";

const PageLoader = ({ darkMode }: PageLoaderProps) => {
	const { loader } = useContext(ComponentContext);

	return (
		<div className={`${(darkMode) ? 'dark-mode' : ''}`}>
			<div id="page-loader" className={`${loader.show ? 'show' : ''}`}></div>
		</div>
	);
}

export default PageLoader;