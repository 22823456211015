import { APIProps } from "./Types/API";
import i18next from "../../i18n";

class API {
  public static baseUrl = process.env.REACT_APP_API_URL;
  private static authPath = "auth";

  static async call({
    endpoint,
    options,
    successCallback,
    errorCallback,
    expiredCallback,
  }: APIProps) {
    try {
      let response = await fetch(API.baseUrl + endpoint, options);

      const res = await response.json();

      switch (response.status) {
        case 200:
        case 201: {
          return successCallback(res);
        }
        default:
          return errorCallback(res);
      }
    } catch {
      return errorCallback({
        statusCode: 500,
        error: {
          code: 500,
          tag: "SERVER_ERROR",
          message: i18next.t("General.errors.SERVER_ERROR"),
        },
      });
    }
  }
}

export default API;
