import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PageLoader from "./components/Loader/PageLoader";
import Alerts from "./components/Alerts/Alerts";
import ComponentContextProvider from "./contexts/Component/Context";
import MainContextProvider from "./contexts/Main/Context";
import LanguageContextProvider from "./contexts/Language/Context";
import EnquiryPage from "./pages/enquiry";
import PageLayout from "./layouts/Page/Layout";
import "./assets/scss/App.scss";
import NotFoundPage from "./pages/NotFoundPage";

function App() {
  return (
    <ComponentContextProvider>
      <Router basename="/">
        <MainContextProvider>
          <LanguageContextProvider>
            <PageLoader darkMode={false} />
            <Alerts />
            <Routes>
              <Route
                path="/"
                element={
                  <PageLayout
                    fixedHeader={false}
                    localeNamespaces={["General"]}
                    component={EnquiryPage}
                  />
                }
              />
              <Route
                path="/*"
                element={
                  <PageLayout
                    fixedHeader={false}
                    localeNamespaces={["General"]}
                    component={NotFoundPage}
                  />
                }
              />
            </Routes>
          </LanguageContextProvider>
        </MainContextProvider>
      </Router>
    </ComponentContextProvider>
  );
}

export default App;
