import { AlertProps } from "./Types/Alert";

const Alert = ({ type, title, message, index, hideAlert }: AlertProps) => {
	const onAnimationEnd = () => {
		hideAlert(index);
	}

	return (
		<div className={`alert alert-${(type === 'error') ? 'danger' : type}`} role="alert" onAnimationEnd={onAnimationEnd}>

			{
				(title) &&
				<h3 className="alert-heading h4 my-2">{title}</h3>
			}

			<p className="mb-0">
				{message}
			</p>
		</div>
	);
};

export default Alert;