enum AlertType
{
	SUCCESS = 'success',
	ERROR = 'danger',
	WARNING = 'warning',
	INFO = 'info',
	PRIMARY = 'primary',
	SECONDARY = 'secondary'
}

type AlertState = {
	show: boolean;
	type: string;
	title?: string;
	message: string;
}

enum AlertActionType
{
	ADD = 'ADD',
	HIDE = 'HIDE'
}

type AlertAction = {
	type: AlertActionType,
	payload: {
		index: number;
		alert: Partial<AlertState>
	}
}

export type {AlertState, AlertAction};
export {AlertActionType,AlertType}