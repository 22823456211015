import React, { createContext, useState, useEffect } from "react";

import i18n from "../../i18n";

const defaultValues = {
  language: "EN",
  setLanguage: (states: string) => {},
};
export const LanguageContext = createContext(defaultValues);

const LanguageContextProvider = (props: any) => {
  const [language, setLanguage] = useState<string>("EN");

  useEffect(() => {
    i18n.changeLanguage(language);
    console.log("Language:", language);
  }, [language]);

  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      {props.children}
    </LanguageContext.Provider>
  );
};

export default LanguageContextProvider;
