import { useTranslation } from "react-i18next";

import { LocaleLoaderProps } from "./Types/LocaleLoader";

const LocaleLoader = ({ namespaces, component: MainContent }: LocaleLoaderProps) => {
	const { t, ready } = useTranslation(namespaces);

	return (
		<>
			{
				(ready) &&
				<MainContent t={t} />
			}
		</>
	);
}

export default LocaleLoader;