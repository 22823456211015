import { useCallback } from "react";

import "./Styles/Layout.scss";

import { PageLayoutProps } from "./Types/Page";
import LocaleLoader from "../../components/LocaleLoader/LocaleLoader";
import Footer from "../../components/Footer";

const PageLayout = ({
  fixedHeader,
  mainContentLayout,
  localeNamespaces,
  component: MainContent,
}: PageLayoutProps) => {
  const generateLayoutClasses = useCallback(() => {
    console.log("LAYOUT CLASS");
    //optional page-header-fixed dark-mode page-header-dark
    let classes = `sidebar-o enable-page-overlay side-trans-enabled`;

    if (fixedHeader) classes += " page-header-fixed";

    if (mainContentLayout) classes += ` main-content-${mainContentLayout}`;

    return classes;
  }, [fixedHeader, mainContentLayout]);

  return (
    <>
      <div id="page-container" className={generateLayoutClasses()}>
        <div id="page-overlay" />
        <div id="modal-overlay" />
        <main id="main-container" className={``}>
          <LocaleLoader namespaces={localeNamespaces} component={MainContent} />
        </main>
      </div>
    </>
  );
};

export default PageLayout;
