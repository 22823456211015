type LoaderState = {
	show: boolean
}

enum LoaderActionType
{
	SHOW = 'SHOW',
	HIDE = 'HIDE'
}

type LoaderAction = {
	type: LoaderActionType,
}

export type {LoaderState, LoaderAction};
export {LoaderActionType}