import { AlertAction, AlertActionType, AlertState } from "../Types/Alert";

const alertReducer = (
  state: AlertState[],
  action: AlertAction
): AlertState[] => {
  switch (action.type) {
    case AlertActionType.ADD: {
      const alerts = JSON.parse(JSON.stringify(state));

      alerts.push({
        show: action.payload.alert.show ?? false,
        type: action.payload.alert.type ?? "",
        title: action.payload.alert.title,
        message: action.payload.alert.message ?? "",
      });

      return alerts;
    }
    case AlertActionType.HIDE: {
      const alerts = JSON.parse(JSON.stringify(state));

      alerts[action.payload.index].show = action.payload.alert.show;

      let hiddenAlerts = 0;

      for (const alert of alerts) {
        if (!alert.show) hiddenAlerts++;
      }

      if (hiddenAlerts === alerts.length) return [];

      return alerts;
    }
    default:
      return state;
  }
};

export { alertReducer };
